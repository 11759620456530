import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Nope, that page doesn&#39;t exist...</p>
    <p>You'll have to head back to https://cv.grahamdaw.com</p> 
  </Layout>
)

export default NotFoundPage
